import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";

function ForgotCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(['', '', '', '']); // Changed to 6 digits

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    setEmail(emailParam || '');
  }, [location]);

  const handleCodeChange = (index, value) => {
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
    } else if (value === '') { // Allow backspace to clear the digit
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);
    }
  };

  const checkCode = async (e) => {
    e.preventDefault();
    try {
      const enteredCode = code.join('');
      const response = await GetRequest(`${API_ENDPOINTS.user.otp}?email=${email}&otp=${enteredCode}`);
      if (response.status === 200) {
        navigate(`/reset/email?email=${email}&otp=${enteredCode}`);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resendCode = async(e)=>{
    e.preventDefault();
    try {
      const response = await GetRequest(`${API_ENDPOINTS.user.email}?email=${email}`);
      if(response.status === 200){
        toast.success(response.message);
      }
      else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="signup">
      <img src="./images/arrow-left.png" className="hand backb" onClick={() => navigate(-1)} alt="direction" replace={true} />

        <div className="signup pop">
          <div className="row">
            <div className="image">
              <img src="./images/logo.png" alt="game" />
            </div>
            <h4 className="pt-3"> Check Email</h4>
            <p style={{ color: "white", fontSize: "12px" }}>
              Please enter the code received on<br />
              your registered email address
            </p>

            <form>
              <h4 style={{ fontSize: "13px", marginLeft: "70px" }} className="text-start">Please Enter 4 Digit code</h4>
              <div className=" text-start">
                <div className="code mx-5">
                  {code.map((value, index) => (
                    <div className="code-inner mx-4" key={index}>
                      <input
                        type="text"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleCodeChange(index, e.target.value)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-light fs-7 text-end mb-2 hand" onClick={resendCode}>
                <u>Resend code</u>
              </div>
              <input
                className="app-btn"
                type="submit"
                value="Done"
                class="create"
                onClick={checkCode}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotCode;
