import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import Pagination from "../components/Pagination";
import LoadingOverlay from "react-loading-overlay";

const PlayerHistory = () => {
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchHistory = async () => {
    try {
      const response = await GetRequest(API_ENDPOINTS.quiz.quizzes);
      if (response.status === 200) {
        setIsLoading(false)
        setHistory(response.data);
      } else {
        console.error("Error fetching history");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < Math.ceil(history.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const renderPageNumbers = () => {
    const totalPageCount = Math.ceil(history.length / itemsPerPage);

    return (
      <Pagination
        currentPage={currentPage}
        totalPageCount={totalPageCount}
        paginate={paginate}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
      />
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <LoadingOverlay active={isLoading} spinner>
    <div className="history">
      <div className="container">
        <div className="row">
          <div className="col-md-6 pt-2">
            <span className="hand" onClick={() => navigate(-1)}>
              <img src="./images/arrow-left.png" alt="direction" />
              <img className="px-3" src="./images/logo.png" alt="game" />
            </span>
          </div>
          <div className="row user">
            <h>Completed Games</h>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Game Type</th>
              <th>Total Scores</th>
              <th>Code</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id}>
                <td className="text-center">{item.id}</td>
                <td>{formatDateTime(item.addedOn)}</td>
                <td>{item.gameType}</td>
                <td>{item.points}</td>
                <td>{item.uniqueCode ? item.uniqueCode : "NA"}</td>{" "}
                <td>
                  <span onClick={() => navigate(`/result/${item.id}?playerid=${item.addedBy}`)}>
                    View Game
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row">
          <div className="col-md-12 text-center m-4">
            <div className="pagination">{renderPageNumbers()}</div>
          </div>
        </div>
      </div>
    </div>
    </LoadingOverlay>
  );
};

export default PlayerHistory;
