import moment from "moment/moment";

function getDevApiUrl() {
  const devApiBaseUrl = localStorage.getItem("dev-api-base-url");
  return devApiBaseUrl;
}

export const BASE_URL = getDevApiUrl() || "https://namegame-apis.npit.pro";

export const ValueFromSearchString = (searchString = "", key = "") => {
  try {
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(key);
  } catch (error) {
    console.log("error", error);
  }
};

export const ReadFromLocalstorage = (key) => {
  return localStorage.getItem(key);
};

export const compareDates = (date1, date2) => {
  // Create Moment.js objects for the input dates
  var momentDate1 = moment(date1);
  var momentDate2 = moment(date2);

  // Compare day, month, and year
  if (momentDate1.isSame(momentDate2, "day")) {
    return 0; // equal
  } else if (momentDate1.isBefore(momentDate2, "day")) {
    return -1; // date1 is lesser
  } else {
    return 1; // date1 is greater
  }
};
