import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import Timer from "../components/Timer";
import LoadingOverlay from "react-loading-overlay";

const Quiz = () => {
  const [errorCount, setErrorCount] = useState("");
  const [questions, setQuestions] = useState([]);
  const [sportOptions, setSportOptions] = useState([]);
  const navigate = useNavigate();
  const { quizId } = useParams();
  const userId = localStorage.getItem("userId");
  const [timerEnd, setTimerEnd] = useState(false);
  const [loading, setloading] = useState(false);
  const [quizeData, setQuizeData] = useState({
    gameType: "",
    uniqueCode: "",
    timing: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [newRowAdded, setNewRowAdded] = useState([]);

  const fetchData = async () => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.getquiz}?id=${quizId}`
      );
      if (response.status === 200) {
        setIsLoading(false);
        setQuestions(
          response.data.map((el) => ({
            ...el,
            answers: [
              { firstNameValue: "", lastNameValue: "", leagueValue: "" },
            ],
          }))
        );
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const numOfPlayers = async () => {
    try {
      let response = await GetRequest(
        `${API_ENDPOINTS.quiz.getQuize}?id=${quizId}`
      );
      if (response.status === 200) {
        setQuizeData({
          gameType: response.data.gameType,
          uniqueCode: response.data.uniqueCode,
          timing: response.data.durationInMins,
          scoring: response.data.scoring,
        });
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let isValid = true;
    if (isValid) {
      let hasEmptyFields = false;
      if(errorCount) {
        return;
      }
      setloading(true);
      questions.forEach(async (question, index) => {
        question.answers.forEach(async (answer, idx) => {
          const quizAnswer = {
            quizQuestionId: question.id,
            firstName: answer.firstNameValue || "",
            lastName: answer.lastNameValue || "",
            league: answer.leagueValue || "",
          };
          try {
            const response = await PostRequest(
              API_ENDPOINTS.quiz.Checkans,
              quizAnswer
            );
            if (!response || response.status !== 200) {
              console.log("API Error");
              hasEmptyFields = true;
            }
          } catch (error) {
            console.log(error);
            hasEmptyFields = true;
          }
          if (
            index + 1 === questions.length &&
            idx + 1 === question.answers.length
          ) {
            if (hasEmptyFields && e) {
              toast.error("Fields are empty");
            } else {
              navigate(`/result/${quizId}?userId=${userId}`, { replace: true });
            }
          }
        });
      });
    }
  };

  const fetchSportOptions = async () => {
    try {
      const response = await GetRequest(API_ENDPOINTS.quiz.leagues);
      if (response.status === 200) {
        setSportOptions(response.data);
      } else {
        console.error("error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleChange = async (
    event,
    name,
    questionIndex,
    answerIndex,
    patternKey,
    markInvalid
  ) => {
    const { value } = event.target;
    let errorMessage = "";
  
    // Check for the pattern only if a value is provided
    if (value && patternKey) {
      const pattern = new RegExp(
        `^[${questions[questionIndex][patternKey]}].*`,
        "i"
      );
      errorMessage = pattern.test(value)
        ? ""
        : `First letter should match with the given letter (${questions[questionIndex][patternKey]}).`;
    }
  
    setQuestions((prev) => {
      const copy = [...prev];
      copy[questionIndex].answers[answerIndex][name] = value;
      // 
        const flag =copy[questionIndex].answers[answerIndex].firstNameValue && copy[questionIndex].answers[answerIndex].lastNameValue && !copy[questionIndex].answers[answerIndex].leagueValue; 
        copy[questionIndex].answers[answerIndex].flagLeagueError = flag;
        setErrorCount((prev) => {
          const qid = (copy[questionIndex].id || "").toString();
          const errorIds = prev.split(",");
          const found = errorIds.includes(qid);
          if(found){
            if(!flag){
              // remove
              if(!prev){
                return '';
              }else{
                return [...errorIds].filter(e=>(e !== qid)).join(',');
              }
            }
          }else{
            if(flag){
              // add
              if(!prev){
                return `${qid}`;
              }else{
                return [...errorIds, qid].join(',');
              }
            }
          }
          return prev;
        });
      // 
      if (patternKey) {
        copy[questionIndex].answers[answerIndex][`${patternKey}Error`] =
          errorMessage;
      }
  
      if (markInvalid && errorMessage) {
        markInvalid();
      }
  
      return [...copy];
    });
  };
  const handleTimerEnd = () => {
    setTimerEnd(true);
  };

  const autoSubmitForm = async () => {
    handleSubmit();
  };

  const AddRow = (questionIndex) => {
    try {
      const copy = [...questions];
      const currentQuestion = copy[questionIndex];
  
      if (currentQuestion.answers.length < 4) {
        currentQuestion.answers.push({
          firstNameValue: "",
          lastNameValue: "",
          leagueValue: "",
        });
  
        setQuestions([...copy]);
  
        if (currentQuestion.answers.length === 4) {
          setNewRowAdded((prev) => [...prev, questionIndex]);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
    fetchData();
    fetchSportOptions();
    numOfPlayers();
  }, []);

  useEffect(() => {
    if (timerEnd) {
      autoSubmitForm();
    }
  }, [timerEnd]);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <div>
          <div className="dashboard game-play">
            <div className="header-nav">
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <span className="hand" onClick={() => navigate("/dashboard")}>
                    <img src="./images/arrow-left.png" alt="direction" />
                    <img className="px-3" src="./images/logo.png" alt="game" />
                  </span>
                </div>
                <div className="d-flex gap-5  mx-5 px-5">
                    <Timer
                      setMin={quizeData.timing}
                      onTimerEnd={handleTimerEnd}
                    />
                </div>
              </div>
            </div>

            <div className="game-section mt-5 pt-5">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-1"></div>
                <div className="col-md-3 mx-3">
                  <h5>FIRST NAME</h5>
                </div>
                <div className="col-md-3 mx-5">
                  <h5>LAST NAME</h5>
                </div>
                {quizeData.scoring !== "MUTUAL_CONSENT" && (
                  <div className="col-md-3 mx-3">
                    <h5>SPORT</h5>
                  </div>
                )}
                <div className="col-md-1"></div>
              </div>
              <Form id="myform" onSubmit={handleSubmit}>
                {questions.map((question, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-1">
                        <div className="game-box" key={index}>
                          {question.firstLetter}
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="game-box" key={index}>
                          {question.secondLetter}
                        </div>
                      </div>
                      <div className="col-md-10">
                        {question.answers.map((answer, idx) => {
                          return (
                            <div className="row">
                              <div className="col-md-4 mb-3">
                                <input
                                  type="text"
                                  value={answer.firstNameValue}
                                  onChange={(e) =>
                                    HandleChange(
                                      e,
                                      "firstNameValue",
                                      index,
                                      idx,
                                      "firstLetter"
                                    )
                                  }
                                />
                                <small className="text-warning small-error-text">
                                  {answer.firstLetterError}
                                  {answer.error}
                                </small>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  value={answer.lastNameValue}
                                  onChange={(e) =>
                                    HandleChange(
                                      e,
                                      "lastNameValue",
                                      index,
                                      idx,
                                      "secondLetter"
                                    )
                                  }
                                />
                                <small className="text-warning small-error-text">
                                  {answer.secondLetterError}
                                  {answer.error}
                                </small>
                              </div>
                              {quizeData.scoring !== "MUTUAL_CONSENT" && (
                                <div className="col-md-3">
                                  <Form.Group controlId={`sportName${index}`}>
                                    <Form.Control
                                      as="select"
                                      placeholder={`Dropdown ${index}`}
                                      value={answer.leagueValue}
                                      onChange={(e) =>
                                        HandleChange(
                                          e,
                                          "leagueValue",
                                          index,
                                          idx
                                        )
                                      }>
                                      <option value="" selected>
                                        Select League
                                      </option>
                                      {sportOptions.map((sport, sportIndex) => {
                                        return (
                                          <option
                                            key={sportIndex}
                                            value={sport.league}>
                                            {sport.league}
                                          </option>
                                        );
                                      })}
                                    </Form.Control>
                                    {
                                      answer.flagLeagueError && <small className="text-warning small-error-text">
                                        Field is required.
                                    </small>
                                    }
                                  </Form.Group>
                                </div>
                              )}
                              <div className="col-md-1">
                                <Button
                                  size="lg"
                                  className="game-box-last p-1 "
                                  onClick={() => AddRow(index)}
                                  style={{
                                    fontSize: "12px",
                                    height:"40px",
                                    display: newRowAdded.includes(index)
                                      ? "none"
                                      : "block",
                                  }}>
                                  + Row
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </Form>
            </div>
          </div>
          <div className="bottom-buttons">
            <div className="row">
              <div className="col-md-6 text-left">
                <button className="cancel-btn" onClick={() => navigate("/")}>
                  Cancel
                </button>
              </div>
              <div className="col-md-6 text-end">
                <button
                  type="submit"
                  className="Submit-btn"
                  form="myform"
                  disabled={loading}>
                  {loading ? (
                    <div class="spinner-border text-light" role="status">
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default Quiz;
