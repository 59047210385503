import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);


  const handleRegularLogin = async (e) => {
    e.preventDefault();

    try {
      const authData = { email, password };
      const response = await PostRequest(API_ENDPOINTS.user.login, authData);

      if (response.status === 200 && response.message === "Successful") {
        toast.success(response.message);
        navigate("/dashboard");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.id);
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.error("Login failed", error);
      toast.error("Sign In failed. Please check your email and password.");
    }
  };


  const handleHowToPlay = () => {
    setShowModal(true);

  };

  const closeModal = () => {
    setShowModal(false);
  };

  const newaccount = () => {
    navigate("/newaccount");
  };

  return (
    <>
      <div className="signup">
        <div className="signup pop">
          <div className="row">
            <div className="image">
            <img src="./images/logo.png" alt="game"/>
            </div>
            <h4 className="pt-3"> Sign In</h4>
            <form>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                required={true}
                placeholder="Username / Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                required={true}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />{" "}
              <a href="#/forgotemail" >Forgot Password?</a>
              <input
                className="app-btn"
                type="submit"
                value="Sign In"
                class="create"
                onClick={(e) => handleRegularLogin(e)}
              />
             {/*  <input
                className="app-btn"
                type="submit"
                value="Create New Account"
                onClick={newaccount}
              /> */}
         <div>version 1.0</div>

              <span className="text custom-text-color fw-bold  hand"   onClick={newaccount} typeof="submit">Create an Account</span>
            </form>
            <span className="text-white hand text-decoration-underline mt-2"   onClick={handleHowToPlay} >How to Play</span>


          </div>
        </div>
      </div>
      <Modal
          show={showModal}
          size="md"
          centered
          className="blur-5"
          onRequestClose={closeModal}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
   <div className="d-flex align-items-center justify-content-end gap-4 text-white modal-header border-0 py-2 px-3" style={{background : 'rebeccapurple'}}>
                            <h6 className="mb-0" id="exampleModalLabel m-auto fw-bold">How To Play</h6>
                            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                          </div>
                          
                          <div className="modal-body text-start fs-6">
                                  <h6 className="fw-semibold small">Objective</h6>
                                  <ul className="small">
                                    <li>List names (first, last) of celebrities associated with 26 two-character 
                                      letter pairs presented on the game sheet.</li>
                                    </ul>
                                    <h6 className="fw-semibold small">Game Play</h6>
                                  <ul className="small">
                                    <li>
                                      Players are presented with a set of 26 two-character letter pairs.
                                    </li>
                                    <li>Players have 5:00 minutes to correctly enter names (first, last) and sports
                                      league associated with the letter pair.</li>
                                      <li>
                                        Correct answers equal 1 point and are totaled for each player to det.
                                      </li>
                                  </ul>
                          </div>
                              </Modal>
    </>
  );
}

export default Login;
