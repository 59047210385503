import React, { useEffect, useState } from "react";
import { GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";

const Invites = () => {
  const [invites, setInvites] = useState([]);
  const navigate = useNavigate();
  const [codeValues, setCodeValues] = useState(["", "", "", "", "", ""]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchInvites = async () => {
    try {
      const response = await GetRequest(API_ENDPOINTS.quiz.invites);
      if (response.status === 200) {
        setIsLoading(false);
        setInvites(response.data);
      } else {
        console.error("Error fetching invites");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rejectInvite = async (inviteId) => {
    try {
      const response = await PostRequest(
        `${API_ENDPOINTS.quiz.reject}?inviteId=${inviteId}`
      );

      if (response.status === 200) {
        toast.success(response.message);
        setInvites((prevInvites) =>
          prevInvites.filter((invite) => invite.id !== inviteId)
        );
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const acceptInvite = async (inviteId, quizId) => {
    try {
      const response = await PostRequest(
        `${API_ENDPOINTS.quiz.accept}?inviteId=${inviteId}`
      );
      if (response.status === 200 && response.data) {
        navigate(`/quiz/${quizId}`);
      } else {
        console.log("Error accepting invite");
      }
    } catch (error) {
      console.log("Error accepting invite");
    }
  };

  const checkUniqueCode = async () => {
    try {
      const uniqueCode = codeValues.join("");
      let response = await GetRequest(
        `${API_ENDPOINTS.quiz.getQuize}?uniqueCode=${uniqueCode}`
      );
      if (response.status === 200) {
        let quizId = response.data.id;
        navigate(`/quiz/${quizId}`);
      } else {
        console.log(response.message);
        toast.error("Incorrect code");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  // const handleClose = () => {
  //   setShow(false);
  //   setChosenInvite(-1);
  //   setCodeValues(["", "", "", "", "", ""]);
  // };

  const handleCodeInputChange = (index, value) => {
    const newCodeValues = [...codeValues];
    if (/^[0-9a-zA-Z]?$/.test(value)) {
      newCodeValues[index] = value.toUpperCase();
      setCodeValues(newCodeValues);
    }
  };
  const handleCodeInputKeyPress = (index, e) => {
    if (e.key === "Enter" && index < 3) {
      const nextIndex = index + 1;
      document.getElementById(`code-input-${nextIndex}`).focus();
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  return (
    <LoadingOverlay active={isLoading} spinner>
      <div className="invites-container h-100">
        <div className="dashboard game-play scoregame  ">
          <div className="header-nav">
            <div className="row">
              <div className="col-md-6">
                <span className="hand" onClick={() => navigate(-1)}>
                  <img src="./images/arrow-left.png" alt="direction" />
                  <img className="px-3" src="./images/logo.png" alt="game" />
                </span>
              </div>
              <div className="text-end">
                <h className="d-flex justify-content-center">
                  Game Invitations
                </h>
                <h4 className="text-light text-start px-5 mb-2 w-100">
                  Enter Existing Code Number
                </h4>
                <div className=" text-start px-4 mx-5">
                  <div className="code">
                    {codeValues.map((value, index) => (
                      <div className="code-inner" key={index}>
                        <input
                          type="text"
                          value={value}
                          onChange={(e) =>
                            handleCodeInputChange(index, e.target.value)
                          }
                          onKeyPress={(e) => handleCodeInputKeyPress(index, e)}
                          id={`code-input-${index}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="code-b text-start">
                  <button
                    className="code-button mt-3"
                    type="button"
                    onClick={checkUniqueCode}>
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-end px-5 mx-5">
            <div
              className="code px-5 mx-5"
              onClick={() => {
                setShow(true);
              }}>
              Enter Code
            </div>
          </div> */}
          <div className="game-section mx-5 px-5">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-1 mx-4"></div>
              <div className="col-md-3">
                <h5 className="text-start">Invited By</h5>
              </div>
              <div className="col-md-4">
                <h5 className="text-start">Invited Date</h5>
              </div>
              <div className="col-md-2">
                <h5 className="text-start">Game Type</h5>
              </div>
            </div>

            {invites.length > 0 ? (
              invites.map((invite) => (
                <div className="row pt-4" key={invite.id}>
                  <div className="col-md-1 text-center">
                    <div
                      className="accept"
                      onClick={() => acceptInvite(invite.id, invite.quizId)}>
                      {" "}
                      Accept
                    </div>
                  </div>
                  <div className="col-md-1 text-center mx-4">
                    <div
                      className="reject"
                      onClick={() => rejectInvite(invite.id)}>
                      Reject
                    </div>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      value={invite.invitingUserFullName}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      value={formatDateTime(invite.inviteDate)}
                      readOnly
                    />
                  </div>

                  <div className="col-md-2">
                    <input type="text" value={invite.gameType} readOnly />
                  </div>
                </div>
              ))
            ) : (
              <div className="row col-md-9 pt-4">
                {!isLoading ? <h4>No Invitations</h4> : ""}
              </div>
            )}

            <div className="score-submit"></div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Invites;
