import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PostRequest, GetRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { Button } from "react-bootstrap";
import playgame from "../assests/images/playgame.png";
import playgame2 from "../assests/images/playgame2.png";
import leader from "../assests/images/leader.png";
import leader1 from "../assests/images/leader1.png";
import groupss from "../assests/images/groupss.png"
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";


const Dashboard = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ FullName: "", logo: "" });
  const [quizId, setQuizId] = useState(0);
  const [groupData , setGroupData] = useState([]);
  const [pendingData , setPendingData] = useState([]);
  const [status , setStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [saveId,setSaveId] = useState([]);

  const userId = localStorage.getItem("userId");


  const gameOfTheDay = async () => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: 0
      };
  
      const response = await PostRequest(`${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=true`, requestBody, 'application/x-www-form-urlencoded');
  
      if (response.status === 200) {
        const data = response.data;
        let quizId = 0;
        if (data && data[0]) {
          quizId = data[0].quizId;
          setQuizId(quizId);
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
        navigate(`/quiz/${quizId}`);
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkQuiz = async () => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: 0
      };
  
      const response = await PostRequest(`${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=true`, requestBody, 'application/x-www-form-urlencoded');
  
      if (response.status === 200) {
        const data = response.data;
        let quizId = 0;
        if (data && data[0]) {
          quizId = data[0].quizId;
          setSaveId(quizId);
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };


  const checkResponse = async (saveId) => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.allResponses}?userId=${userId}&quizId=${saveId}`
      );
      if (response.status === 200) {
      } else {
        console.error("error");
      }
    } catch (error) {
      console.error(error);
    }
  };


  const groups = async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.group.allGroup}?pageNumber=${1}&pageSize=${100}&filters=${"MY_GROUPS"}`);
      if (response.status === 200) {
        setGroupData(response.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pendingGroups = async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.group.allGroup}?pageNumber=${1}&pageSize=${100}&filters=${"PENDING_INVITE"}`);
      if (response.status === 200) {
        setPendingData(response.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const user = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.userDetails}`);
      if (response.status === 200 && response.data) {
        setUserData({
          FullName: response.data.fullName,
        });
        setStatus(response.data.hasPlayedGameOfTheDay);
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };


    useEffect(()=>{
      checkQuiz();
      user();
      groups();
      pendingGroups();
    },[]);

    useEffect(()=>{
      checkResponse(saveId);
    },[saveId])
   
    const JoinGroups = async (groupId) => {
      try {
        const response = await PostRequest(
          `${API_ENDPOINTS.group.pending}?groupId=${groupId}&action=${"ACCEPT"}`
        );
        if (response.status === 200) {
          await groups();
          await pendingGroups();
          toast.success(response.message)
        }else{
          toast.error(response.message)
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const RejectGroups = async (groupId) => {
      try {
        const response = await PostRequest(
          `${API_ENDPOINTS.group.pending}?groupId=${groupId}&action=${"REJECT"}`
        );
        if (response.status === 200) {
          await pendingGroups();
          toast.error(response.message)
        }
        else{
          toast.error(response.message)
        }
      } catch (error) {
        console.log(error);
      }
    };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleHowToPlay = () => {
    setShowModal(true);

  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-right">
          <div className="header-nav">
            <div className="row ">
              <div className="col-md-6">
                <img
                  src="./images/logo.png"
                  onClick={() => navigate("/")}
                  alt="game"
                />
                            <span className="text-white hand text-decoration-underline mx-4 mt-2"   onClick={handleHowToPlay} >How to Play</span>
                            <span className="text-white hand text-decoration-underline mt-2"   onClick={()=>navigate("/invite-a-friend")} >Invite A Friend</span>

              </div>

              <div className="col-md-6 text-right p-3">
                <Link to="/profile">
                  <div className="user d-flex align-items-center justify-content-between">
                    <img
                      src={userData.logo || "./images/dummy3.png"}
                      alt="profile"
                    />
                    <label>{userData.FullName}</label>
                    <Button
                      variant={Link}
                      className="text-light text-decoration-underline"
                      onClick={logout}>
                      Logout
                    </Button>
                  </div>
                </Link>
              </div>

            </div>
          </div>
          <Modal
          show={showModal}
          size="md"
          centered
          className="blur-5"
          onRequestClose={closeModal}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
   <div className="d-flex align-items-center justify-content-end gap-4 text-white modal-header border-0 py-2 px-3" style={{background : 'rebeccapurple'}}>
                            <h6 className="mb-0" id="exampleModalLabel m-auto fw-bold">How To Play</h6>
                            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                          </div>
                          <div className="modal-body text-start fs-6">
                                  <h6 className="fw-semibold small">Objective</h6>
                                  <ul className="small">
                                    <li>List names (first, last) of celebrities associated with 26 two-character 
                                      letter pairs presented on the game sheet.</li>
                                    </ul>
                                    <h6 className="fw-semibold small">Game Play</h6>
                                  <ul className="small">
                                    <li>
                                      Players are presented with a set of 26 two-character letter pairs.
                                    </li>
                                    <li>Players have 5:00 minutes to correctly enter names (first, last) and sports
                                      league associated with the letter pair.</li>
                                      <li>
                                        Correct answers equal 1 point and are totaled for each player to det.
                                      </li>
                                  </ul>
                          </div>
                              </Modal>

          <div className="games-container  d-flex justify-content-start">
            <div className="game">
              <h5 className="text-center pb-5">Game Of The Day</h5>
              <div className="row d-flex justify-content-center">
                <div className="col-md-5 text-center">
                  <div
                    className="games-inner px-4"
                    style={{ backgroundImage: `url(${playgame})` }}>
                    <img src={playgame2} alt="game" />
                    <p>Game Of The Day</p>
                    <button 
  disabled={status > 0} 
  style={{ backgroundColor: status > 0 ? '#808080' : '' }} 
  onClick={gameOfTheDay}
>
  Play Game
</button>                  </div>
                </div>

                <div className="col-md-5 text-center">
                  <div
                    className="games-inner  px-4"
                    onClick={() => navigate("/leader")}
                    style={{ backgroundImage: `url(${leader})` }}>
                    <img src={leader1} alt="game" />
                    <p>Game Of The Day</p>
                    <button>LeaderBoard</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="game1 ml-3">
  <h5 className="text-center">Play With Friends</h5>
  <div className="row d-flex justify-content-start align-items-center">
    <div className="col-md-6 text-center">
      <div className="games-inner hand px-4" >
        <button className="button1 fs-6" onClick={() => navigate("/allgroup")}>View All Groups</button>
      </div>
    </div>

    <div className="col-md-6 text-center">
      <div className="games-inner hand px-4">
        <button className="button2  fs-6" onClick={() => navigate("/newgroup/add")}>Start New Group</button>
      </div>
    </div>
    <div className="col-md-6">
      <h5 className="heading fs-6"> Groups</h5>
    </div>
    
    <div className="col-md-6">
      <h5 className="view hand fs-6" onClick={() => navigate("/allgroup")}>View All</h5>
    </div>
    <div className="col-md-12 d-flex flex-wrap justify-content">
  {groupData.slice(0,4).map((group, index) => (
    <div key={index} className="games-inner px-4 text-center border border-light mx-3" >
      <img src={groupss} alt="game" />
      <p>{group.name}</p>
      <button onClick={() => navigate(`/group/${group.id}`)}>Play</button>

      <button className="buttonleader"onClick={() => navigate(`/group/${group.id}`)}>LeaderBoard</button>

    </div>
  ))}
</div>


    <div className="col-md-6 ">
      <h5 className="heading pt-4 fs-6">Pending Invitations</h5>

    </div> 
     <div className="col-md-6 text-light text-end  hand" onClick={() => navigate("/pending")}>
      <u>      View All
</u>
    </div>
     <div className="col-md-12 d-flex justify-content mt-2">
  {pendingData.slice(0,4).map((group, index) => (
    <div key={index} className="games-inner px-4 text-center border border-light mx-3">
      <img src={groupss} alt="game" />
      <p>{group.name}</p>
      <div className="group-button">
                    <button
                    className="btn-left"
                    onClick={() => JoinGroups(group.id)}
                  
                  >
                    Join
                  </button>
                      <button className="buttonleader hand"  onClick={() => RejectGroups(group.id)}>Reject</button>
                    </div>
 
    </div>
  ))}
</div>
  </div>
</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
