import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrash,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import groupss from "../assests/images/groupss.png";
import { Modal } from "react-bootstrap";
import { DeleteRequest, GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const GroupName = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalRem, setShowModalRem] = useState(false);
  const [showModalMem, setShowModalMem] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [myGroupData, setMyGroupData] = useState([]);
  const [playButtonEnabled, setPlayButtonEnabled] = useState(false);
  const [games, setGames] = useState([]);
  const location = useLocation();
  const [quizId, setQuizId] = useState(0);
  const [removeId , setRemoveId] = useState(0);
  const [viewGame, setViewGame] = useState(0);
  const [mem, setMem] = useState([]);
  const [leaderData, setLeaderData] = useState([]);
  const [userData, setUserData] = useState({ FullName: "", logo: "" });
  const userId = localStorage.getItem("userId");
  const [activeGameId, setActiveGameId] = useState(0);
  const [status , setStatus] = useState([]);


  const user = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.userDetails}`);
      if (response.status === 200 && response.data) {
        setUserData({
          FullName: response.data.fullName,
        });
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMembers = async () => {
    try {
      const response = await GetRequest(
        `${
          API_ENDPOINTS.group.members
        }?groupId=${id}&pageNumber=${1}&pageSize=${10}`
      );
      if (response.status === 200) {
        setMem(response.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const startNewGame = async () => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: id,
      };

      const response = await PostRequest(
        `${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=false`,
        requestBody,
        "application/x-www-form-urlencoded"
      );

      if (response.status === 200) {
        const data = response.data;
        let quizId = 0;
        if (data && data[0]) {
          quizId = data[0].quizId;
          setQuizId(quizId);
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
        navigate(`/quiz/${quizId}`);
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getIdFromUrl = () => {
    const urlParts = location.pathname.split("/");
    return urlParts[urlParts.length - 1];
  };

  const id = getIdFromUrl();

  const fetchDetails = async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.group.get}?id=${id}`);
      if (response.status === 200) {
        setGroupData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const playgame = async (id) => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.quiz.getquiz}?id=${id}`);
      if (response.status === 200) {
        setPlayButtonEnabled(true);
        navigate(`/quiz/${id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const gameDetails = async () => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.quizzes}?userId=${0}&groupId=${id}`
      );
      if (response.status === 200) {
        const gamesData = response.data;
        // Check if any game has been played
        const played = gamesData.some(game => game.hasPlayed);
        // Set status to true if any game has been played, otherwise false
        setStatus(played);
        setGames(gamesData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleDelete = async () => {
    try {
      const response = await DeleteRequest(
        `${API_ENDPOINTS.group.delete}?id=${id}`
      );
      if (response.status === 200) {
        closeModal();
        navigate("/allgroup", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleRemoveMember = (memberId) => {
    setShowModalRem(true);
    setRemoveId(memberId)
  };

  const showMembers = () => {
    setShowModalMem(true);
  };
  const closeModalRemove = () => {
    setShowModalRem(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeModalMem = () => {
    setShowModalMem(false);
  };
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const removeMember = async () => {
    try {
      const id = getIdFromUrl();
      const response = await PostRequest(
        `${
          API_ENDPOINTS.group.add
        }?groupId=${id}&userId=${removeId}&action=${"REMOVE"}`
      );
      if (response.status === 200) {
        navigate("/allgroup", { replace: true })
        toast.success(response.message);
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLeaderBoard = async (gameId) => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.user.leader}?pageNumber=${1}&pageSize=${10}&query=${"all"}&quizId=${gameId}`);
      if (response.status === 200 && response.data) {
        setLeaderData(response.data.items);
        setViewGame(gameId); 
        // Update active game id and play button status
        setActiveGameId(gameId);
        const played = games.find(game => game.id === gameId)?.hasPlayed || false;
        setPlayButtonEnabled(!played);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const groups = async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.group.allGroup}?pageNumber=${1}&pageSize=${500}&filters=${"MY_GROUPS"}`);
      if (response.status === 200) {
        setMyGroupData(response.data.items);
      
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (myid) => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.allResponses}?userId=${
          myid
        }&quizId=${viewGame}`
      );
      if (response.status === 200) {
        navigate(`/result/${viewGame}?userId=${myid}`)
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    fetchDetails();
    getMembers();
    gameDetails();
    user();
    groups();

  }, []);


  useEffect(() => {
    if (games.length > 0) {
      fetchLeaderBoard(games[0].id);
      setActiveGameId(games[0].id);
    }
  }, [games]);

useEffect(() => {
  if (games.length > 0) {
    const played = games.some(game => game.hasPlayed);
    setStatus(played);
    setPlayButtonEnabled(!played);
    setGames(games);
  }
}, [games]);


  return (
    <div className="dashboard">
      <div className="dashboard-right">
        <div className="header-nav">
          <div className="row ">
            <div className="col-md-6">
            <img src="./images/arrow-left.png" className="hand"  onClick={() => navigate(-1)} alt="direction" />

              <img
                src="./images/logo.png"
                className="mx-3 hand"
                onClick={() => navigate("/")}
                alt="game"
              />
            </div>

            <div className="col-md-6 text-right p-3 mb-2">
              <Link to="/profile">
                <div className="user d-flex align-items-center justify-content-between">
                  <img
                    src={userData.logo || "./images/dummy3.png"}
                    alt="profile"
                  />
                  <label>{userData.FullName}</label>
                  <Button
                    variant={Link}
                    className="text-light text-decoration-underline"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="group-name">
            <div className="groupname-top">
              <div className="row">
                <div className="col-md-6">
                  {groupData && (
                    <h2>
                      {groupData.name}{" "}
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="hand"
                        style={{ fontSize: "12px" }}
                        onClick={() => navigate(`/newgroup/${groupData.id}`)}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ fontSize: "12px" }}
                        className="mx-2 hand"
                        onClick={handleDeleteClick}
                      />
                    </h2>
                  )}
                </div>
            
                <div className="col-md-6 text-right">
                  {/* <button
                    onClick={showMembers}
                    className="mx-2"
                    style={{ backgroundColor: "#F59A30" }}
                  >
                    View Members
                  </button> */}
                  <button onClick={() => startNewGame()}>Start New Game</button>
                </div>
           

              </div>
            </div>

            <div className="game-history">
              <div className="row">
                <div className="col-md-4">
                  <h2>History Of Game</h2>
                  <div className="history-left">
                    {games.map((game, index) => (
                      <div
                        className={`history-box-left mx-3   my-2  ${
                          game.id === activeGameId ? "active" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          fetchLeaderBoard(game.id);
                          setActiveGameId(game.id);
                        }}
                      >
                        <img className="mx-2" src={groupss} alt="game group" />
                        <span className="mx-3">{game.name}</span>
                        <div className="games-inner px-3 w-100  mb-3">
                        <button 
  onClick={() => playgame(game.id)}
  disabled={game.hasPlayed} 
  style={{ backgroundColor: game.hasPlayed ? '#808080' : '#8E68D8' }} 
>
  Play
</button>


                          <button  onClick={() => {
                          fetchLeaderBoard(game.id);
                          setActiveGameId(game.id);
                        }}
                            style={{ backgroundColor:"#6F5E91",marginLeft:"8px"}} 
                          >
                            LeaderBoard
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row history-heading">
                    <div className="col-md-3 text-right">
                      <h2>Rank</h2>
                    </div>
                    <div className="col-md-3 text-right">
                      <h2>Username</h2>
                    </div>
                    <div className="col-md-3 text-right">
                      <h2>Score</h2>
                    </div>
                    <div className="col-md-3 text-right">
                      <h2>View</h2>
                    </div>
                  </div>
                  <table className="d-flex flex-column">
    <tbody>
      {leaderData.map((leader, index) => (
        <tr key={index}>
          <td className="">{index + 1}</td>
          <td>{leader.username}</td>
          <td>{leader.points}</td>
          <td>
          <div 
  className="hand" 
  onClick={() => {
    if (!playButtonEnabled) {
      // Only execute the function if playButtonEnabled is true
      fetchData(leader.id);
    }
  }}
  style={{ color: playButtonEnabled ? '#808080' : '#FFFFFF' }}
>
  View Game
</div>

          </td>
        </tr>
      ))}
    </tbody>
  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showModalMem}
          size=""
          centered
          className="blur-5"
          onRequestClose={closeModalMem}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
          <div>
            <div className="container">
              <div
                className="cross mt-3 mx-3 hand"
                style={{ color: "white" }}
                onClick={closeModalMem}
              >
                X
              </div>
              <h3 className="d-flex justify-content-center text-white mb-3 ">
                Group Members
              </h3>
              <div className="member-modal">
                <div className="g-member ">
                  <div className="members-vertical">
                    {mem.map((data, index) => (
                      <div
                        className="d-flex align-items-center mb-2 newmember"
                        key={index}
                      >
                        <div className="member">
                          <label>
                            <span>{index + 1}</span>
                            <span className="mx-2 mb-3 ">{data.firstName}</span>
                            
                          </label>
                        </div>
                     
                        <div className="ml-auto removeMem">
                        
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ fontSize: "12px" }}
                            className="text-white hand"
                            onClick={() => handleRemoveMember(data.userId)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div
                    className="member add hand  addmember mt-5 "
                    onClick={() => navigate(`/newgroup/${groupData.id}`)}
                  >
                    <labelname className="text-white pb-3">+ Add Members</labelname>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          show={showModalRem}
          size="md"
          centered
          className="blur-5"
          onRequestClose={closeModalRemove}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
          <div className="history-pop">
            <div
              className="cross float-end hand"
              style={{ color: "white" }}
              onClick={closeModalRemove}
            >
              X
            </div>
            <h3> Do You Want To Leave?</h3>
            <div className="buttons-pop">
              <button className="btn-1" onClick={closeModalRemove}>
                No
              </button>
              <button className="bt-2" onClick={removeMember}>
                Yes
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          show={showModal}
          size="md"
          centered
          className="blur-5"
          onRequestClose={closeModal}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
          <div className="history-pop">
          <FontAwesomeIcon
icon={faTimes}
className="hand float-end text-light fs-4 mx-3"
style={{ fontSize: "12px" }}
onClick={closeModal}
/>
            <h3>Please Confirm Do You Want To Leave The Group?</h3>
            <div className="buttons-pop">
              <button className="btn-1" onClick={closeModal}>
                No
              </button>
              <button className="bt-2" onClick={handleDelete}>
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GroupName;
