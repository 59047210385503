import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";


function ForgotEmail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");


 const sendEmail = async(e)=>{
  e.preventDefault();
  try {
    const response = await GetRequest(`${API_ENDPOINTS.user.email}?email=${email}`);
    if(response.status === 200){
      toast.success(response.message);
      navigate(`/code/email?email=${email}`);     }
      else{
        toast.error(response.message)
      }
  } catch (error) {
    
  }
 }


  return (
    <>
    

      <div className="signup">
      <img src="./images/arrow-left.png" className="hand backb" onClick={() => navigate("/")} alt="direction" replace={true} />

        <div className="signup pop">
          <div className="row">
            <div className="image">
            <img src="./images/logo.png" alt="game"/>
            </div>
            <h4 className="pt-3"> Forgot Password?</h4>
            <p style={{color:"white",fontSize:"12px"}}>
    Enter your registered email to receive <br/>
     code to reset your password
</p>

            <form>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                required={true}
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
             
              <input
                className="app-btn "
                type="submit"
               style={{backgroundColor:"#8e68d8"}}
                value="Send"
                class="create"
                onClick={sendEmail}
              />
           
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotEmail;
