import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import groupss from "../assests/images/groupss.png";
import { PostRequest, GetRequest } from "../services/api";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes
} from "@fortawesome/free-solid-svg-icons";

const NewGroup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [groupName, setGroupName] = useState("");
  const [emailsOrUserNames, setEmailsOrUserNames] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [additionalPlayers, setAdditionalPlayers] = useState(0);
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState({ FullName: "", logo: "" });
  const [selectedUsers, setSelectedUsers] = useState([null]); 
  const [users, setUsers] = useState([]);
  const [mem, setMem] = useState([]);
  const [pendingMem, setPendingMem] = useState([]);

  const isAddMode = location.pathname.includes("/newgroup/add");


  const user = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.userDetails}`);
      if (response.status === 200 && response.data) {
        setUserData({
          FullName: response.data.fullName,
        });
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllUsers = async (query) => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.user.userAll}?pageNumber=${1}&pageSize=${100}&query=${
          query || ""
        }`
      );
      if (response.status === 200 && response.data) {
        const formattedUsers = response.data.items.map((user) => ({
          value: user.id,
          label: user.fullName,
        }));
        setUsers(formattedUsers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMembers = async () => {
    try {
      const response = await GetRequest(
        `${
          API_ENDPOINTS.group.members
        }?groupId=${id}&pageNumber=${1}&pageSize=${10}&filters=${"ACCEPTED"}`
      );
      if (response.status === 200) {
        setMem(response.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingMembers = async () => {
    try {
      const response = await GetRequest(
        `${
          API_ENDPOINTS.group.members
        }?groupId=${id}&pageNumber=${1}&pageSize=${10}&filters=${"PENDING"}`
      );
      if (response.status === 200) {
        setPendingMem(response.data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  const getIdFromUrl = () => {
    const urlParts = location.pathname.split("/");
    return urlParts[urlParts.length - 1];
  };

  const addPlayerInput = () => {
    setAdditionalPlayers((prevCount) => prevCount + 1);
    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, null]); 
  };

  const addMember = async (index) => {
    try {
      const id = getIdFromUrl();
      const selectedUserId = selectedUsers[index] ? selectedUsers[index].value : null;
      if (selectedUserId) {
        const response = await PostRequest(
          `${API_ENDPOINTS.group.add}?groupId=${id}&userId=${selectedUserId}&action=${"ADD"}`
        );
        if (response.status === 200) {
          toast.success(response.message);
          getMembers();
    getPendingMembers();
        }
        else{
          toast.error(response.message)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const id = getIdFromUrl();

  const fetchDetails = async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.group.get}?id=${id}`);
      if (response.status === 200) {
        setGroupData([response.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveGroup = async () => {
    try {
      let groupIdToSend = id;
      if (groupData.length === 0) {
        groupIdToSend = 0;
      }
  
      // Determine the name to send in the API request
      let nameToSend = groupName;
      if (!nameToSend && groupData.length > 0) {
        // If groupName is empty, use the initially fetched group name
        nameToSend = groupData[0].name;
      }
  
      const params = {
        id: groupIdToSend,
        name: nameToSend,
        emailsOrUserNames: emailsOrUserNames,
      };
  
      const response = await PostRequest(API_ENDPOINTS.group.saveGroup, params);
      if (response.status === 200) {
        navigate("/allgroup", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleUserSelect = (selectedOption, index) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = [...prevSelectedUsers];
      newSelectedUsers[index] = selectedOption;
      return newSelectedUsers;
    });
  };
  
  const handleUserSearch = (query) => {
    fetchAllUsers(query);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    fetchDetails();
    getMembers();
    getPendingMembers();
    user();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-right">
        <div className="header-nav">
          <div className="row ">
            <div className="col-md-6">
            <img src="./images/arrow-left.png" className="hand"  onClick={() => navigate(-1)} alt="direction" />
              <img src="./images/logo.png" className="mx-3 hand" onClick={() => navigate("/")} alt="game" />
            </div>

            <div className="col-md-6 text-right p-3">
              <Link to="/profile">
                <div className="user d-flex align-items-center justify-content-between">
                  <img src={userData.logo || "./images/dummy3.png"} alt="profile" />
                  <label>{userData.FullName}</label>
                  <Button variant={Link} className="text-light text-decoration-underline" onClick={logout}>
                    Logout
                  </Button>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="group-detail">
          <div className="signup pop">
          <FontAwesomeIcon
icon={faTimes}
className="hand float-end text-light fs-4 mx-3"
style={{ fontSize: "12px" }}
onClick={() => navigate(-1)}/>
            <div className="cross">
              <h2>Group Detail </h2>
            </div>

            <div className="group-inner">
            <img src={groupss} />

              <form>
                {groupData.length > 0 ? (
                  groupData &&
                  groupData.map((group, index) => {
                    return (
                      <div className="g-name" key={index}>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter Group Name"
                          value={groupName || group.name || ""}
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="g-name">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Group Name"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </div>
                )}

{!isAddMode && (
                  <>
                    <div className="d-flex justify-content-center text-white mb-2 mt-2">
                      Group Members
                    </div>
                    <div className="member-modal">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="g-member mb-3">
                            <div className="members-vertical">
                              <h6 className="text-light">Accepted</h6>
                              {mem.map((data, index) => (
                                <div
                                  className="d-flex align-items-center mb-2 newmember"
                                  key={index}
                                >
                                  <div className="member">
                                    <label style={{ width: "100%" }}>
                                      <span>{index + 1}</span>
                                      <span className="mx-2 mb-3 ">{data.fullName}</span>
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="g-member mb-3">
                            <div className="members-vertical">
                              <h6 className="text-light">Pending</h6>
                              {pendingMem.map((data, index) => (
                                <div
                                  className="d-flex align-items-center mb-2 newmember"
                                  key={index}
                                >
                                  <div className="member ">
                                    <label style={{ width: "100%" }}>
                                      <span>{index + 1}</span>
                                      <span className="mx-2 mb-3 ">{data.fullName}</span>
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {selectedUsers.map((selectedUser, index) => (
                      <div className="row form-detail" key={index}>
                        <div className="col-md-8">
                          <Select
                            value={selectedUser}
                            onChange={(selectedOption) => handleUserSelect(selectedOption, index)}
                            options={users}
                            onInputChange={handleUserSearch}
                            placeholder="Search and Select User"
                          />
                        </div>
                        <div className="col-md-4">
                          <button type="button" onClick={() => addMember(index)}>
                            + Add To Group
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {/* <button className="add-player" onClick={addPlayerInput}>
                  Add Player
                </button> */}
                <button type="button" className="submit" onClick={saveGroup}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewGroup;
