import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRequest } from "../services/api";
import { PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { Modal, Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import result from "../assests/images/result.svg";
import { QUIZ_LINK } from "../constants/apiEndpoints";

const Result = () => {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showChallengeModal, setShowChallengeModal] = useState(false);
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const [isLoading, setIsLoading] = useState(true);
  const [quizeData, setQuizeData] = useState("");
  const [groupId, setGroupId] = useState("");
  const [day,setDay]=useState("");
  const [groupName,setGroupName]=useState("");
  const [score, setScore] = useState("");
  const [userData, setUserData] = useState("");
  const [friendEmails, setFriendEmails] = useState(["", "", ""]);
  const mylocalId = localStorage.getItem("userId");

  const handleChallengeClose = () => {
    setIsChallengeModalOpen(false);
  };
  

  const fetchLetter = async () => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.getquiz}?id=${quizId}`
      );
      if (response.status === 200) {
        setIsLoading(false);
        console.log("successful");
      } else {
        console.error("error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.allResponses}?userId=${
          userId
        }&quizId=${quizId}`
      );
      if (response.status === 200) {
        const initializedQuestions = response.data.map((question) => ({
          ...question,
          matchStatus: "",
        }));
        setQuestions(initializedQuestions);
      } else {
        console.error("error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkMatchStatus = (question) => {
    return question.isCorrect ? "right.svg" : "wrong.svg";
  };

  const handleChallengeFriend = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/;

    const validEmails = friendEmails.filter((email) => email.trim() !== "");
    if (validEmails.length === 0) {
      toast.error("Please enter at least one valid email address");
      return;
    }

    for (const email of validEmails) {
      if (!emailRegex.test(email)) {
        toast.error("Invalid email address");
        return;
      }
    }

    try {
      for (const email of validEmails) {
        const response = await PostRequest(
          `${API_ENDPOINTS.quiz.sendInvite}?quizId=${quizId}&&email=${email}`
        );

        if (response.status === 200) {
          toast.success(`Challenge has been sent to ${email} successfully!`);
          setShowChallengeModal(false);
        } else {
          console.error("Error");
        }
      }
      setIsChallengeModalOpen(true);
        } catch (error) {
      console.error(error);
    }
  };

  const [players, setPlayers] = useState([]);
  const numOfPlayers = async () => {
    try {
      let response = await GetRequest(
        `${API_ENDPOINTS.quiz.getQuize}?id=${quizId}`
      );
      if (response.status === 200) {
        setGroupName(response.data.groupName)
        setGroupId(response.data.groupId);
        setPlayers(response.data.players);
        setQuizeData(response.data.scoring);
        setScore(response.data.scoring);
        setDay(response.data.gameOfTheDay);

      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const user = async () => {
    try {
      const response = await PostRequest(API_ENDPOINTS.user.userDetails);

      if (response.status === 200 && response.data) {
        setUserData(response.data.fullName)
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    user();
  },[])

  useEffect(() => {
    if (!isChallengeModalOpen) {
      setFriendEmails(["", "", ""]);
    }
  }, [isChallengeModalOpen]);

  useEffect(() => {
    fetchLetter();
    fetchData();
    numOfPlayers();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const matchedCount = questions.filter(
    (question) => question.isCorrect
  ).length;

  const handleClose = () => {
    setShow(false);
  };

  const handleFriend = () => {
    setFriendEmails([...friendEmails, ""]);
  };

  const handleFacebookClick = () => {
    const quizLink = `${QUIZ_LINK}/${quizId}?userId=${mylocalId}`;
    const postText = encodeURIComponent(`I just tested my sports knowledge on NameDropt: Sports Edition’s Game of the Day. I ${userData} ${matchedCount} celebrities.\n\nPlay Now:\n${quizLink}\n\n`);
    const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${quizLink}&quote=${postText}`;
    window.open(facebookShareURL, "_blank");
  };
  

  const handleEmailClick = () => {
    const subject = encodeURIComponent("Check out this link");
    const quizLink = `${QUIZ_LINK}/${quizId}?userId=${mylocalId}`;
    const body = encodeURIComponent(`I just tested my sports knowledge on NameDropt: Sports Edition’s Game of the Day. I ${userData} ${matchedCount} celebrities.\n\nPlay Now:\n${quizLink}\n\nThanks,\n${userData}`);
    const emailShareURL = `mailto:?subject=${subject}&body=${body}`;

    window.open(emailShareURL, "_blank");
  };
  
  const handleEmailClickPlay = () => {
    const subject = encodeURIComponent("Check out this link");
    const quizLink = `${QUIZ_LINK}/${quizId}?userId=${mylocalId}`;
    const body = encodeURIComponent(` I just played a new game in our private group ${groupName} on NameDropt: Sports Edition. I Name Dropt ${matchedCount} celebrities.\n\nPlay Now:\n${quizLink}\n\nThanks,\n${userData}`);
    const emailShareURL = `mailto:?subject=${subject}&body=${body}`;

    window.open(emailShareURL, "_blank");
  };

  const handleXClick = () => {
    const quizLink = `${QUIZ_LINK}/${quizId}?userId=${mylocalId}`;
    const tweetText = encodeURIComponent(`I just tested my sports knowledge on NameDropt: Sports Edition’s Game of the Day. I ${userData} ${matchedCount} celebrities. Play Now: ${quizLink}`);
    const twitterShareURL = `https://twitter.com/intent/tweet?text=${tweetText}&url=${quizLink}`;
    window.open(twitterShareURL, "_blank");
  };
  


  return (
    <LoadingOverlay active={isLoading} spinner>
      <Modal
        className="share-popup blur-5"
        centered
        show={show}
        size="md"
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton closeVariant="white">
          <div className="result-header">
            <h4>Share Results</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="share-boxes">
            {day && (
              <div onClick={handleFacebookClick} className="share hand">
                <img src="./images/facebook.png" alt="Facebook" />
                <label>Facebook</label>
              </div>
               )}
  
              {/* <div onClick={() => navigate("/")} className='share hand'>
                <img src='./images/messanger.png' alt="" />
                <label>Messenger</label>
              </div> */}
         
              <div onClick={day ? handleEmailClick : handleEmailClickPlay} className="share hand">
                <img src="./images/e-mail.png" alt="Email" />
                <label>Email</label>
              </div>
              {day && (
              <div onClick={handleXClick} className="share hand">
                <img src="./images/x.png" alt="X" />
                <label>X</label>
              </div>
            )}
              
              {/* <div onClick={handleInstagramClick} className="share hand">
                <img src="./images/instagram.png" alt="Instagram" />
                <label>Instagram</label>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
  className=" share-popup blur-5"
  centered
  show={showChallengeModal}
  onHide={() => {
    setShowChallengeModal(false);
    setIsChallengeModalOpen(false);
  }}
  backdrop="static"
>
        <Modal.Header closeButton closeVariant="white" >
          <div className="result-header px-5 mx-5">
            <h4 className="mx-5">Invite a Friend</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFriendEmail">
              <h6 className="text-start">Friend's Email</h6>
              {friendEmails.map((email, index) => (
                <div key={index} className="pt-2">
                  <Form.Control
                    type="email"
                    placeholder={`Enter friend's email ${index + 1}`}
                    value={email}
                    onChange={(e) => {
                      const updatedEmails = [...friendEmails];
                      updatedEmails[index] = e.target.value;
                      setFriendEmails(updatedEmails);
                    }}
                  />
                </div>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#e06464" }}
            variant="primary"
            onClick={handleFriend}
          >
            + Friend
          </Button>
          <Button variant="primary" onClick={handleChallengeFriend}>
            Send Challenge
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <div className="dashboard game-play results multiplayer">
          <div className="header-nav">
            <div className="row">
              <div className="col-md-12">
                <img
                  src="./images/arrow-left.png"
                  onClick={() => navigate(-1)}
                  alt="back"
                />
                <img
                  className="px-3 hand"
                  onClick={() => navigate("/dashboard")}
                  src="./images/logo.png"
                  alt="game"
                />{" "}
              </div>
              <div className="d-grid d-md-flex justify-content-md-end mb-5 me-md-8">
  {day ? (
    <button className="btn btn-dark py-3 px-2 leader " type="button" onClick={() => navigate("/leader")}>
      <img
        src={result}
        style={{ width: 37, height: 36 }}
        alt="leader"
      />
      <span className="mx-2 mt-3 fs-5 text-light">Leaderboard</span>
    </button>
  ) : (
    <button className="btn btn-dark py-3 px-2 leader " type="button" onClick={() => navigate(`/group/${quizId}/${groupId}`)}>
      <img
        src={result}
        style={{ width: 37, height: 36 }}
        alt="leader"
      />
      <span className="mx-2 mt-3 fs-5 text-light">Leaderboard</span>
    </button>
  )}
</div>

              {/* <div className="col-md-12 text-end MultiPlayerHeading mb-5 px-5">
              
                <img src={result} alt="leader" className="leader" />
                <span className=" mx-2 fs-3">Leaderboard</span>
              </div> */}
            </div>
          </div>

          <div className="game-sections mx-5 px-5">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <h5>FIRST NAME</h5>
              </div>
              <div className="col-md-3">
                <h5>LAST NAME</h5>
              </div>
              {quizeData !== "MUTUAL_CONSENT" && (
                <div className="col-md-3">
                  <h5>SPORT</h5>
                </div>
              )}
              <div className="col-md-1"></div>
            </div>

            {questions.map((question, index) => {
              const matchStatus = checkMatchStatus(question);
              return (
                <div className="row">
                  <div className="col-md-1">
                    <div className="game-box" key={index}>
                      {question.firstLetter}
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="game-box" key={index}>
                      {question.secondLetter}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <input type="text" value={question.firstName} />
                  </div>

                  <div className="col-md-3">
                    <input type="text" value={question.lastName} />
                  </div>
                  {quizeData !== "MUTUAL_CONSENT" && (
                    <div className="col-md-3">
                      <input type="text" value={question.league} />
                    </div>
                  )}
                  {quizeData !== "MUTUAL_CONSENT" && (
                    <>
                      <div className="col-md-1">
                        <div className="result-end">
                          <img
                            src={`./images/${matchStatus}`}
                            style={{ height: "30px" }}
                            alt="match status"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="bottom-buttons">
          <div className="row">
            <div className="col-md-6 text-left"></div>
            <div className="col-md-6 text-right">
              <div className="score">
                {score === "MUTUAL_CONSENT" ? (
                  ""
                ) : (
                  <>
                    <div className="total-score">
                      <span>TOTAL SCORE</span> {matchedCount}
                    </div>
                  </>
                )}
                
                <div className="button-container">
                {mylocalId === userId && (
  <>
    <button className="share-btn" onClick={() => setShow(true)}>
      Share Results
    </button>
    </>
)}
    {day && (
      <button
        className="share-btn2 mt-2"
        onClick={() => setShowChallengeModal(true)}
      >
        Invite a Friend
      </button>
    )}


            
                  <button
                    className="share-btn mt-2"
                    onClick={() => navigate("/dashboard")}
                  >
                    Go to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Result;
