import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import {  Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";


const NewGame = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    durationInMins: 5,
    scoring: "VERIFY",
    gameType: "SINGLE_PLAYER",
  });
  const [loading, setLoading] = useState(false);
  const [friendEmails, setFriendEmails] = useState([""]);
  const [challengeSent, setChallengeSent] = useState(false);
  const [quizId, setQuizId] = useState(0);
  const [validator, setValidator] = useState({
    durationInMins: false,
    gameType: false,
    scoring: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const startNewGame = async () => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: 0
      };
  
      const response = await PostRequest(`${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=false`, requestBody, 'application/x-www-form-urlencoded');
  
      if (response.status === 200) {
        const data = response.data;
        let quizId = 0;
        if (data && data[0]) {
          quizId = data[0].quizId;
          setQuizId(quizId);
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
        navigate(`/quiz/${quizId}`);
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChallengeFriend = async (quizId) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/;

    const validEmails = friendEmails.filter((email) => email.trim() !== "");
    if (validEmails.length === 0) {
      toast.error("Please enter at least one valid email address");
      return;
    }

    for (const email of validEmails) {
      if (!emailRegex.test(email)) {
        toast.error("Invalid email address");
        return;
      }
    }

    try {
      for (const email of validEmails) {
        const response = await PostRequest(
          `${API_ENDPOINTS.quiz.sendInvite}?quizId=${quizId}&&email=${email}`
        );

        if (response.status === 200) {
          toast.success(`Challenge has been sent to ${email} successfully!`);
        } else {
          console.error("Error");
          setChallengeSent(false);
          return;
        }
      }
      setChallengeSent(true);
    } catch (error) {
      console.error(error);
      setChallengeSent(false);
    }
  };



  const handleFriend = () => {
    console.log("abc", friendEmails);
    setFriendEmails([...friendEmails, ""]);
  };

  useEffect(() => {
    if (quizId > 0 && inputs.gameType === "MULTI_PLAYER") {
      handleChallengeFriend(quizId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId, inputs.gameType]);

  return (
    <>
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div
                className="hand"
                style={{ position: "absolute", top: "15px", left: "15px" }}>
                <img
                  src="./images/arrow-left.png"
                  onClick={() => navigate(-1)}
                  alt="back"
                />
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="login-form">
                <img
                  className="hand"
                  onClick={() => navigate(-1)}
                  src="./images/logo.png"
                  alt="game"
                />
                <h2 className="pt-3">Start New Game</h2>

                <form onSubmit={startNewGame}>
                  <div className="checkboxes">
                    <h6 className="mx-3">TIME</h6>

                    <label htmlFor="duration5" className="hand">
                      <input
                        className="mx-2"
                        type="radio"
                        id="duration5"
                        name="durationInMins"
                        value={5}
                        disabled={challengeSent}
                        onChange={handleChange}
                        checked={inputs.durationInMins == 5}
                      />
                      5 mins
                    </label>

                    <label htmlFor="duration10" className="hand">
                      <input
                        className="mx-2"
                        type="radio"
                        id="duration10"
                        name="durationInMins"
                        value={10}
                        disabled={challengeSent}
                        onChange={handleChange}
                        checked={inputs.durationInMins == 10}
                      />
                      10 mins
                    </label>
                    <br />
                    {
                      <small className="text-danger small-error-text">
                        {validator.durationInMins
                          ? "Please select a valid time duration"
                          : ""}
                      </small>
                    }
                  </div>

                  <div className="checkboxes">
                    <h6 className="mx-3">SCORING</h6>
                    <label
                      htmlFor="verifyRadio"
                      className="hand"
                      title="Scoring based on matches with the NameDropt database">
                      <input
                        className="mx-2"
                        type="radio"
                        id="verifyRadio"
                        name="scoring"
                        disabled={challengeSent}
                        value="VERIFY"
                        onChange={handleChange}
                        checked={inputs.scoring === "VERIFY"}
                      />
                      Verify
                    </label>
                    <label
                      htmlFor="mutualConsentRadio"
                      className="hand"
                      title="Scoring determined by a review of the opposing player ">
                      <input
                        className="mx-2"
                        type="radio"
                        id="mutualConsentRadio"
                        name="scoring"
                        disabled={challengeSent}
                        value="MUTUAL_CONSENT"
                        onChange={handleChange}
                        checked={inputs.scoring === "MUTUAL_CONSENT"}
                      />
                      Mutual Consent
                    </label>
                    <br />
                  </div>

                  <div className="checkboxes">
                    <h6 className="mx-3">PLAYERS</h6>
                    <label htmlFor="individualRadio" className="hand">
                      <input
                        className="mx-2"
                        type="radio"
                        id="individualRadio"
                        name="gameType"
                        disabled={challengeSent}
                        value="SINGLE_PLAYER"
                        onChange={handleChange}
                        checked={inputs.gameType === "SINGLE_PLAYER"}
                      />
                      Individual
                    </label>

                    <label htmlFor="multiplayerRadio" className="hand">
                      <input
                        className="mx-2"
                        type="radio"
                        id="multiplayerRadio"
                        name="gameType"
                        value="MULTI_PLAYER"
                        disabled={challengeSent}
                        onChange={handleChange}
                        checked={inputs.gameType === "MULTI_PLAYER"}
                      />
                      Multiplayer
                    </label>

                    <br />
                    <small className="text-danger small-error-text">
                      {validator.gameType
                        ? "Please choose the game type (Individual or Multiplayer)."
                        : ""}
                    </small>
                  </div>

                  <div className="play-btn">
                    {inputs.gameType === "MULTI_PLAYER" && (
                      <>
                        <div>
                          <h6 className="text-start">
                            Enter an email to Invite{" "}
                          </h6>
                          {friendEmails.map((email, index) => (
                            <div key={index} className="pt-2">
                              <Form.Control
                                type="email"
                                placeholder={`Enter email ${index + 1}`}
                                value={email}
                                onChange={(e) => {
                                  const updatedEmails = [...friendEmails];
                                  updatedEmails[index] = e.target.value;
                                  setFriendEmails(updatedEmails);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="">
                          <Button
                            className="mt-2 mb-2"
                            style={{ backgroundColor: "#e06464" }}
                            variant="primary"
                            onClick={handleFriend}
                            disabled={challengeSent}>
                            + Email
                          </Button>
                          <Button
                            variant="primary"
                            className="pt-2"
                            onClick={startNewGame}
                            disabled={challengeSent}>
                            Send Challenge
                          </Button>
                        </div>
                      </>
                    )}
                    {inputs.gameType === "MULTI_PLAYER" ? 
                    <>
                    <button
                      type="submit"
                      className="mt-2"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={()=> navigate(`/quiz/${quizId}`)}
                      disabled={!challengeSent}>
                      {/* {loading ? (
                        <div class="spinner-border text-light" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : (
                        "Let's Play"
                      )} */}
                      Let's Play
                    </button>
                    </> :
                    <>
                    <button
                      type="submit"
                      className="mt-2"
                      data-toggle="modal"
                      data-target="#myModal"
                      disabled={loading}>
                      {loading ? (
                        <div class="spinner-border text-light" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : (
                        "Let's Play"
                      )}
                    </button>
                    </>}
                    
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewGame;
