import React, { useState, useEffect } from "react";
import { DeleteRequest, GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { useNavigate , Link} from "react-router-dom";
import { Button } from "react-bootstrap";
import groupss from "../assests/images/groupss.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt,faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";


const AllGroup = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [groupsId, setGroupsId] = useState([]);
  const [quizId, setQuizId] = useState(0);
  const [userData, setUserData] = useState({ FullName: "", logo: "" });
  const [showModalMem, setShowModalMem] = useState(false);
  const [mem, setMem] = useState([]);
  const [showModalRem, setShowModalRem] = useState(false);
  const [removeId , setRemoveId] = useState(0);
  const [mygroupId , setMyGroupId] = useState(0);
  const [playButtonClicked, setPlayButtonClicked] = useState(false);
  const [myDetails,setMyDetails] = useState([]);

  const userId = localStorage.getItem("userId");

  const user = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.userDetails}`);
      if (response.status === 200 && response.data) {
        setUserData({
          FullName: response.data.fullName,
        });
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startNewGame = async (groupId) => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: groupId
      };
  
      const response = await PostRequest(`${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=false`, requestBody, 'application/x-www-form-urlencoded');
  
      if (response.status === 200) {
        const data = response.data;
        let quizId = 0;
        if (data && data[0]) {
          quizId = data[0].quizId;
          setQuizId(quizId);
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
        navigate(`/quiz/${quizId}`);
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMembers = async (groupId) => {
    try {
      const response = await GetRequest(
        `${
          API_ENDPOINTS.group.members
        }?groupId=${groupId}&pageNumber=${1}&pageSize=${10}`
      );
      if (response.status === 200) {
        setMem(response.data.items);
        setMyGroupId(groupId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const gameDetails = async (mygroup) => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.quiz.quizzes}?userId=${userId}&groupId=${mygroup}`
      );
      if (response.status === 200) {
        setMyDetails(response.data);
        console.log(response.data,"mydummydata")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeMember = async () => {
    try {
      const response = await PostRequest(
        `${
          API_ENDPOINTS.group.add
        }?groupId=${mygroupId}&userId=${removeId}&action=${"REMOVE"}`
      );
      if (response.status === 200) {
        navigate("/allgroup", { replace: true })
        toast.success(response.message);
      }
      else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const groups = async () => {
    try {
      const response = await GetRequest(`${API_ENDPOINTS.group.allGroup}?pageNumber=${1}&pageSize=${500}&filters=${"MY_GROUPS"}`);
      if (response.status === 200) {
        setGroupData(response.data.items);
      
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const showMembers = (groupId) => {
    setShowModalMem(true);
    getMembers(groupId); 
  };

  const closeModalMem = () => {
    setShowModalMem(false);
  };

  const closeModalRemove = () => {
    setShowModalRem(false);
  };

  const handleDeleteClick = (groupId) => {
    setShowModal(true);
    setGroupsId(groupId);

  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRemoveMember = (memberId) => {
    setShowModalRem(true);
    setRemoveId(memberId)
  };

  const handleDelete = async () => {
    try {
      const response = await DeleteRequest(
        `${API_ENDPOINTS.group.delete}?id=${groupsId}`
      );
      if (response.status === 200) {
        closeModal();
        navigate("/allgroup", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    groups();
    user();
    gameDetails();
  }, []);

  return (
    <div className="dashboard">
    <div className="dashboard-right">
     <div className="header-nav">
            <div className="row ">
              <div className="col-md-6">
              <img src="./images/arrow-left.png" className="hand" onClick={() => navigate("/")} alt="direction" replace={true} />
                <img
                  src="./images/logo.png"
                  className="mx-3 hand"
                  onClick={() => navigate("/")}
                  alt="game"
                />
              </div>

              <div className="col-md-6 text-right p-3">
                <Link to="/profile">
                  <div className="user d-flex align-items-center justify-content-between">
                    <img
                      src={userData.logo || "./images/dummy3.png"}
                      alt="profile"
                    />
                    <label>{userData.FullName}</label>
                    <Button
                      variant={Link}
                      className="text-light text-decoration-underline"
                      onClick={logout}>
                      Logout
                    </Button>
                  </div>
                </Link>
              </div>

            </div>
          </div>
      <div className="container">
        <div className="new-group">
          <div className="row group-top">
            <div className="col-md-6 start-group">
              <button onClick={() => navigate("/newgroup/add")}>
                Start New Group
              </button>
              
            </div>
            <div className="col-md-6 start-group text-end "
         >
              
              <button onClick={() => navigate("/pending")}
                 style={{backgroundColor:"#f59a30"}}>
                Pending Invites
              </button>
              
            </div>
            {/* <div className="col-md-6 text-right filter">filter</div> */}
          </div>
          <div className="group-outer gap-3">
            <div className="row ">
            {groupData.map((group, index) => (
  <div
    key={index}
    className="col-md-4 text-center"
    style={{ marginBottom: "20px" }}
  >
    <div
      className="group-box"
    >
      <div className="group-img">
        <img src={groupss} alt="group" />
      </div>
      <div className="group-text-top">
        <label>{group.name}</label>{" "}
        <i className="fa fa-pencil" aria-hidden="true">
          {" "}
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="hand"
            style={{ fontSize: "12px" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/newgroup/${group.id}`);
            }}
          />
          
        </i>
        <i className="fa fa-pencil" aria-hidden="true">
          
        <FontAwesomeIcon
                        icon={faTrash}
                        style={{ fontSize: "12px" }}
                        className="mx-2 hand"
                        onClick={() => handleDeleteClick(group.id)}                       />
                              </i>

      </div>
      <div className="group-button">
      <button
            className="btn-left px-5"
            onClick={() => navigate(`/group/${group.id}`)}
            disabled={myDetails.length > 0} 
            style={{ color: myDetails.length > 0 ? "#808080" : "" }} 
          >
            Play
          </button>
        <button className="btn-right hand"  onClick={() => navigate(`/group/${group.id}`)}>LeaderBoard</button>
        <button className="btn-right hand mt-2" onClick={() => showMembers(group.id)}>View Member</button>
      </div>
    </div>
  </div>
))}

            </div>
          </div>
        </div>
      </div>
      <Modal
          show={showModalMem}
          size=""
          centered
          className="blur-5"
          onRequestClose={closeModalMem}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
          <div>
            <div className="container">
            <FontAwesomeIcon
icon={faTimes}
className="hand float-end text-light fs-4 mx-3"
style={{ fontSize: "12px" }}
onClick={closeModalMem}
/>
              <h3 className="d-flex justify-content-center text-white mb-4 mt-4 ">
                Group Members
              </h3>
              <div className="member-modal">
                <div className="g-member ">
                  <div className="members-vertical">
                    {mem.map((data, index) => (
                      <div
                        className="d-flex align-items-center mb-2 newmember"
                        key={index}
                      >
                        <div className="w-100 text-white d-flex align-items-center justify-content-between">
                          <label>
                            <span>{index + 1}</span>
                            <span className="mx-2 mb-3 ">{data.firstName}</span>
                            
                          </label>
                          <span className="gap-5 ">
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ fontSize: "12px" }}
                            className="text-white hand"
                            onClick={() => handleRemoveMember(data.userId)}
                          />
                          </span>
                        </div>
                      
                      
                    
                      </div>
                    ))}
                  </div>

                  <div
                    className="member add hand  addmember mt-5 "
                    onClick={() => navigate(`/newgroup/${mygroupId}`)}
                  >
                    <labelname className="text-white pb-3 ">Add Members</labelname>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          show={showModalRem}
          size="md"
          centered
          className="blur-5"
          onRequestClose={closeModalRemove}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
          <div className="history-pop">
          <FontAwesomeIcon
icon={faTimes}
className="hand float-end text-light fs-4 mx-3"
style={{ fontSize: "12px" }}
onClick={closeModalRemove}
/>
            <h3>Please Confirm Do You Want To Leave?</h3>
            <div className="buttons-pop">
              <button className="btn-1" onClick={closeModalRemove}>
                No
              </button>
              <button className="bt-2" onClick={removeMember}>
                Yes
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={showModal}
          size="md"
          centered
          className="blur-5"
          onRequestClose={closeModal}
          contentLabel="Confirmation Modal"
          backdrop="static"
        >
          <div className="history-pop">
          <FontAwesomeIcon
icon={faTimes}
className="hand float-end text-light fs-4 mx-3"
style={{ fontSize: "12px" }}
onClick={closeModal}
/>
            <h3>Please Confirm Do You Want To Leave The Group?</h3>
            <div className="buttons-pop">
              <button className="btn-1" onClick={closeModal}>
                No
              </button>
              <button className="bt-2" onClick={handleDelete}>
                Yes
              </button>
            </div>
          </div>
        </Modal>
    </div>
    </div>
  );
};

export default AllGroup;
