import React, { useState, useEffect } from "react";
import { GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { useNavigate , Link} from "react-router-dom";
import { Button } from "react-bootstrap";
import groupss from "../assests/images/groupss.png";
import { toast } from "react-toastify";


const PendingGroups = () => {
  const navigate = useNavigate();
  const [groupData, setGroupData] = useState([]);
  const [quizId, setQuizId] = useState(0);
  const [userData, setUserData] = useState({ FullName: "", logo: "" });
 
  const [playButtonClicked, setPlayButtonClicked] = useState(false);


  const user = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.userDetails}`);
      if (response.status === 200 && response.data) {
        setUserData({
          FullName: response.data.fullName,
        });
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const JoinGroups = async (groupId) => {
    try {
      const response = await PostRequest(
        `${API_ENDPOINTS.group.pending}?groupId=${groupId}&action=${"ACCEPT"}`
      );
      if (response.status === 200) {
        toast.success(response.message);
        setGroupData(prevGroups => prevGroups.filter(group => group.id !== groupId));
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const RejectGroups = async (groupId) => {
    try {
      const response = await PostRequest(
        `${API_ENDPOINTS.group.pending}?groupId=${groupId}&action=${"REJECT"}`
      );
      if (response.status === 200) {
        toast.success(response.message);
        setGroupData(prevGroups => prevGroups.filter(group => group.id !== groupId));
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const groups = async () => {
    try {
        const response = await GetRequest(`${API_ENDPOINTS.group.allGroup}?pageNumber=${1}&pageSize=${500}&filters=${"PENDING_INVITE"}`);
        if (response.status === 200) {
        setGroupData(response.data.items);
      
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

 

 


  useEffect(() => {
    groups();
    user();


  }, []);

  return (
    <div className="dashboard">
    <div className="dashboard-right">
     <div className="header-nav">
            <div className="row ">
              <div className="col-md-6">
              <img src="./images/arrow-left.png" className="hand" onClick={() => navigate("/")} alt="direction" replace={true} />
                <img
                  src="./images/logo.png"
                  className="mx-3 hand"
                  onClick={() => navigate("/")}
                  alt="game"
                />
              </div>

              <div className="col-md-6 text-right p-3">
                <Link to="/profile">
                  <div className="user d-flex align-items-center justify-content-between">
                    <img
                      src={userData.logo || "./images/dummy3.png"}
                      alt="profile"
                    />
                    <label>{userData.FullName}</label>
                    <Button
                      variant={Link}
                      className="text-light text-decoration-underline"
                      onClick={logout}>
                      Logout
                    </Button>
                  </div>
                </Link>
              </div>

            </div>
          </div>
      <div className="container">
        <div className="new-group">
          <div className="row group-top">
            <div className="col-md-6 start-group">
              <button onClick={() => navigate("/newgroup/add")}>
                Start New Group
              </button>
              
            </div>
            <div className="col-md-6 start-group text-end "
         >
              
              <button onClick={() => navigate("/allgroup")}
                 style={{backgroundColor:"#f59a30"}}>
               My Groups
              </button>
              
            </div>
            {/* <div className="col-md-6 text-right filter">filter</div> */}
          </div>
          <div className="group-outer gap-3">
            <div className="row ">
              {groupData.map((group, index) => (
                <div
                  key={index}
                  className="col-md-4 text-center"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="group-box"
                  >
                    <div className="group-img">
                      <img src={groupss} alt="group" />
                    </div>
                    <div className="group-text-top">
                      <label>{group.name}</label>{" "}
                      {/* <i className="fa fa-pencil" aria-hidden="true">
                        {" "}
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          className="hand"
                          style={{ fontSize: "12px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/newgroup/${group.id}`);
                          }}
                        />
                      </i> */}
                    </div>
                    <div className="group-button">
                    <button
                    className="btn-left"
                    onClick={() => JoinGroups(group.id)}
                  
                  >
                    Join
                  </button>
                      <button className="btn-right hand"  onClick={() => RejectGroups(group.id)}>Reject</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
   
      
    </div>
    </div>
  );
};

export default PendingGroups;
