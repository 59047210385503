import React, { useEffect, useState, useRef } from "react";
import { useGameTimer } from "../Utilities/utility";
import { useNavigate, useParams } from "react-router-dom";
import beep2 from "../assests/audio/beep2.mp3"
import { toast } from "react-toastify";

const Timer = ({setMin,onTimerEnd}) => {
  const audioRef = useRef(new Audio(beep2));
  const { minutes, seconds } = useGameTimer(5);
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [played, setPlayed] = useState(false);

  const playAudio = () => {
    try {
      audioRef.current.play();
      setPlayed(true);
    } catch (error) {
      console.log("Error playing audio", error);
    }
  };

  useEffect(() => {
  
    if(minutes === 0 && seconds===30)
    {
      toast.warning('Your time is about to end')
    }
    if (minutes === 0 && seconds < 6 && !played) {
      playAudio();
    }
    if(minutes === 0 && seconds===0)
    {
    onTimerEnd()
    }
  }, [minutes, seconds, navigate, quizId, played]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      audioRef.current = null;
    };
  }, []);
  const timerStyle = {
    position: "fixed",
    top: 41,
    right: 0,
    width: "180px",
    height: "auto",
  };

  return (
    <div className="col-md-8 h-100">
      <div className="user" style={timerStyle}>
        <label>TIME REMAINING</label>
        <p className={`${minutes === 0 && seconds < 10 ? "text-danger" : ""}`}>
          {minutes}:{seconds < 10 ? "0" + seconds : seconds} minutes
        </p>
      </div>
    </div>
  );
};

export default Timer;
