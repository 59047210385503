import React, { useState, useEffect } from "react";
import { GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { Link, useNavigate } from "react-router-dom";
import InputCtrl from "../Controllers/InputCtrl";
import { toast } from "react-toastify";
import Toastify from "../components/Toastify";

const Profile = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [profileData, setProfileData] = useState("");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState({
    id: "",
    fullName: "",
    logo: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });

  const user = async () => {
    try {
      const response = await PostRequest(API_ENDPOINTS.user.userDetails);

      if (response.status === 200 && response.data) {
        setUserData({
          id: response.data.id,
          fullName: response.data.fullName,
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          password: "",
        });
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const profileDetails = async () => {
    try {
      const response = await GetRequest(
        `${API_ENDPOINTS.user.stats}?userId=${userId}`
      );
      if (response.status === 200 && response.data) {
        setProfileData(response.data);
        console.log("successful");
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const updateData = async () => {
    try {
      const response = await PostRequest(API_ENDPOINTS.user.savedata, {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        password: userData.password,
        roleId: 2,
      });

      if (response.status === 200) {
        toast.success(response.message);
        navigate("/dashboard");
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await updateData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    user();
    profileDetails();
  }, []);

  return (
    <>
      <Toastify />
      <div>
        <div className="game-play profile">
          <div className="header-nav">
            <div className="row">
              <div className="col-md-5">
                <Link to="/dashboard">
                  <img src="./images/arrow-left.png" alt="profileImg" />
                </Link>
                <h4>Profile</h4>
              </div>
              <div className="col-md-6">
                <img
                  src="./images/logo.png"
                  onClick={() => navigate("/")}
                  alt="game"
                />
              </div>
            </div>
          </div>

          <div className="row profile-inner mx-5 ">
       
            <div className="col-md-12 mx-5 px-5 ">
              <form className="mx-5"> 
                <p>
                  <InputCtrl
                    type={"text"}
                    placeholder={"First Name"}
                    label={"First Name"}
                    required={true}
                    name={"firstName"}
                    value={userData.firstName}
                    onChange={(e) =>
                      setUserData({ ...userData, firstName: e.target.value })
                    }
                  />
                </p>
                <p>
                  <InputCtrl
                    type={"text"}
                    placeholder={"Last Name"}
                    label={"Last Name"}
                    required={true}
                    name={"lastName"}
                    value={userData.lastName}
                    onChange={(e) =>
                      setUserData({ ...userData, lastName: e.target.value })
                    }
                  />
                </p>
                <p>
                  <InputCtrl
                    type={"email"}
                    placeholder={"Email Name"}
                    label={"Email Address"}
                    required={true}
                    name={"email"}
                    value={userData.email}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                  />
                </p>
                <p>
                  <InputCtrl
                    type={showPassword ? "text" : "password"}
                    placeholder={"Password"}
                    label={"Password"}
                    required={false}
                    name={"password"}
                    value={userData.password}
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                  />
                  <small className="fs-xs text-end w-100 d-block fs-10">
                    Leave field empty if don't want to change password
                  </small>
                </p>
                <div className="col-md-12 submit updatebutton">
                  <input type="submit" value="Update" onClick={handleUpdate} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
