import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    const otpParam = searchParams.get("otp");
    setEmail(emailParam || '');
    setOtp(otpParam || '');
  }, [location]);

  const ChangePassword = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password must match.");
      return;
    }

    try {
      const response = await GetRequest(`${API_ENDPOINTS.user.password}?email=${email}&otp=${otp}&newPassword=${newPassword}`);
      if (response.status === 200) {
        navigate("/");
        toast.success("Password has changed successfully");

      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("Failed to reset password. Please try again.");
    }
  };

  return (
    <>
      <div className="signup">
      <img src="./images/arrow-left.png" className="hand backb" onClick={() => navigate(-1)} alt="direction" replace={true} />

        <div className="signup pop">
          <div className="row">
            <div className="image">
              <img src="./images/logo.png" alt="game" />
            </div>
            <h4 className="pt-3">Reset Password</h4>
            <p style={{ color: "white", fontSize: "12px" }}>
              Your new password must be different <br />
              from previously used password
            </p>

            <form>
            <div className="input-container">
  <input
    type={showNewPassword ? "text" : "password"}
    name="newPassword"
    id="newPassword"
    value={newPassword}
    onChange={(e) => setNewPassword(e.target.value)}
    required={true}
    placeholder="New Password"
    className="input-field"
  />
  <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
    {showNewPassword ? <FaRegEyeSlash /> : <FaRegEye />}
  </span>
</div>

<div className="input-container">
  <input
    type={showConfirmPassword ? "text" : "password"}
    name="confirmPassword"
    id="confirmPassword"
    value={confirmPassword}
    onChange={(e) => setConfirmPassword(e.target.value)}
    required={true}
    placeholder="Confirm Password"
    className="input-field"
  />
  <span className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
    {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
  </span>
</div>


              <input
                className="app-btn"
                type="submit"
                value="Reset Password"
                class="create"
                onClick={ChangePassword}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
