import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GetRequest, PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { compareDates } from "../common/utility";

const Leaderboard = () => {
  const navigate = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [userData, setUserData] = useState({ FullName: "", logo: "" });
  const [quizId, setQuizId] = useState(0);
  const [leaderData, setLeaderData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewGame, setViewGame] = useState(0);
  const [status, setStatus] = useState([]);


  const gameOfTheDayLeaderboard = async () => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: 0
      };
  
      const response = await PostRequest(`${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=true`, requestBody, 'application/x-www-form-urlencoded');
  
      if (response.status === 200) {
        const data = response.data;
        let quizId = 0;
        if (data && data[0]) {
          quizId = data[0].quizId;
          setQuizId(quizId);
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
        navigate(`/quiz/${quizId}`);
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const user = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.userDetails}`);
      if (response.status === 200 && response.data) {
        setUserData({
          FullName: response.data.fullName,
        });
        setStatus(response.data.hasPlayedGameOfTheDay);
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const gameOfTheDay = async () => {
    try {
      const requestBody = {
        durationInMins: 0,
        scoring: "string",
        gameType: "string",
        inviteEmails: "string",
        groupId: 0,
      };

      const response = await PostRequest(
        `${API_ENDPOINTS.quiz.generateQuiz}?getAnswers=false&gameOfTheDay=true`,
        requestBody,
        "application/x-www-form-urlencoded"
      );

      if (response.status === 200) {
        const data = response.data;
        let _quizId = 0;
        if (data && data[0]) {
          _quizId = data[0].quizId;
          setQuizId(_quizId);
          fetchLeaderBoard(_quizId, "today");
        } else {
          throw new Error("Error occurred while generating Quiz!");
        }
        // navigate(`/quiz/${quizId}`);
      } else {
        throw new Error("Error occurred while generating Quiz!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDatePicker = (date) => {
    setShowDatePicker(!showDatePicker);
    if (date) {
      fetchLeaderBoard(quizId, date);
    }
  };

  const fetchLeaderBoard = async (qid, date) => {
    try {
      let formattedDate;
      if (date === "today") {
        formattedDate = new Date().toISOString().slice(0, 10);
      } else if (date === "yesterday") {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        formattedDate = yesterday.toISOString().slice(0, 10);
      } else {
        formattedDate = date.toISOString().slice(0, 10);
      }

      const response = await GetRequest(
        `${
          API_ENDPOINTS.user.leader
        }?pageNumber=${1}&pageSize=${10}&query=${"all"}&date=${formattedDate}&filters=${"GAME_OF_THE_DAY"}`
      );
      if (response.status === 200 && response.data) {
        setLeaderData(response.data.items);
        setViewGame(qid);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    gameOfTheDay();
    user();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-right">
        <div className="header-nav">
          <div className="row ">
            <div className="col-md-6">
              <img
                src="./images/arrow-left.png"
                className="hand"
                onClick={() => navigate(-1)}
                alt="direction"
              />
              <img
                src="./images/logo.png"
                className="mx-3 hand"
                onClick={() => navigate("/")}
                alt="game"
              />
            </div>

            <div className="col-md-6 text-right p-3">
              <Link to="/profile">
                <div className="user d-flex align-items-center justify-content-between">
                  <img
                    src={userData.logo || "./images/dummy3.png"}
                    alt="profile"
                  />
                  <label>{userData.FullName}</label>
                  <Button
                    variant={Link}
                    className="text-light text-decoration-underline"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="group-name leaderboard">
            <div className="groupname-top">
              <div className="row">
  
                <div className="col-md-10 text-center mx-5">
 
                  <h2>Leaderboard Game Of The Day</h2>
                 
                </div>
                
              </div>
              <div className="w-50">
    <button 
  disabled={status > 0} 
  style={{ backgroundColor: status > 0 ? '#808080' : '' }} 
  onClick={gameOfTheDayLeaderboard}
>
  Play Game
</button>        
</div>
            </div>
            <div className="game-history">
              <div className="row">
                <div className="col-md-4">
                  <h2></h2>
                  <div className="history-left">
                    <div className="history-box calendar hand">
                      <h2 onClick={() => toggleDatePicker("today")}>
                        Today{" "}
                        <i
                          className="fa fa-angle-right "
                          aria-hidden="true"
                        ></i>
                      </h2>
                    </div>
                    <div className="history-box calendar hand">
                      <h2 onClick={() => toggleDatePicker("yesterday")}>
                        Yesterday{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </h2>
                    </div>
                    <div
                      className="history-box calendar hand"
                      onClick={() => toggleDatePicker()}
                    >
                      <h2>
                        Select Date{" "}
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </h2>
                    </div>
                    {showDatePicker && (
                      <div className="calendar-container">
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => {
                            setSelectedDate(date);
                            toggleDatePicker(date);
                          }}
                          maxDate={new Date()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row history-heading">
                    <div className="col-md-3 text-right">
                      <h2>Rank</h2>
                    </div>
                    <div className="col-md-3 text-right">
                      <h2>Username</h2>
                    </div>
                    <div className="col-md-3 text-right">
                      <h2>Score</h2>
                    </div>
                    <div className="col-md-3 text-right">
                      <h2>View</h2>
                    </div>
                  </div>
                  <table className="d-flex flex-column">
                    <tbody>
                      {leaderData.map((leader, index) => (
                        <tr key={index} style={{ width: "100%" }}>
                          <td className="">{index + 1}</td>
                          <td>{leader.username}</td>
                          <td>{leader.points}</td>
                          <td>
                            {status ? (
                              <div
                              className="hand"
                                onClick={() =>
                                  navigate(
                                    `/result/${leader.quizId}?userId=${leader.id}`,
                                    { replace: true }
                                  )
                                }
                                style={{ color: "#FFFFFF" }}
                              >
                                View Game
                              </div>
                            ) : (
                              <div
                              className="hand"
                              onClick={() =>
                                {
                                  if(compareDates(leader.quizPlayedOn, new Date()) !== 0){
                                    navigate(
                                      `/result/${leader.quizId}?userId=${leader.id}`,
                                      { replace: true }
                                    )
                                  }
                                }
                              }
                                style={{
                                  color: compareDates(leader.quizPlayedOn, new Date()) === 0 ? "#808080" : "#FFFFFF",
                                }}
                              >
                                View Game
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
