
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import AllRoutes from "./routes/AllRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
   <>
    <Router>
      <AllRoutes/>
      <ToastContainer />
    </Router>
   </>
  );
};

export default App;
