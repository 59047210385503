import React from "react";

const Pagination = ({ currentPage, totalPageCount, paginate, handlePrevClick, handleNextClick }) => {
  const pageNumbersToShow = 5;
  let startPage, endPage;

  if (totalPageCount <= pageNumbersToShow) {
    startPage = 1;
    endPage = totalPageCount;
  } else {
    const middlePage = Math.ceil(pageNumbersToShow / 2);

    if (currentPage <= middlePage) {
      startPage = 1;
      endPage = pageNumbersToShow;
    } else if (currentPage + middlePage >= totalPageCount) {
      startPage = totalPageCount - pageNumbersToShow + 1;
      endPage = totalPageCount;
    } else {
      startPage = currentPage - middlePage + 1;
      endPage = currentPage + middlePage - 1;
    }
  }

  return (
    <div className="pagination-box">
      <span onClick={handlePrevClick} className="pagination-button">
        &laquo; Prev
      </span>
      {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
        <span
          key={startPage + index}
          onClick={() => paginate(startPage + index)}
          className={currentPage === startPage + index ? "active" : ""}
        >
          {startPage + index}
        </span>
      ))}
      <span onClick={handleNextClick} className="pagination-button">
        Next &raquo;
      </span>
    </div>
  );
};

export default Pagination;
