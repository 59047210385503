import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "../pages/Login";
import NewAccount from "../pages/NewAccount";
import Quiz from "../pages/Quiz";
import Result from "../pages/Result";
import Dashboard from "../pages/Dashboard";
import PlayerHistory from "../pages/PlayerHistory";
import Profile from "../pages/Profile";
import NewGame from "../pages/NewGame";
import Invites from "../pages/Invites";
import Leaderboard from "../pages/LeaderBoard";
import AllGroup from "../pages/AllGroup";
import GroupName from "../pages/GroupName";
import NewGroup from "../pages/NewGroup";
import ForgotEmail from "../pages/ForgotEmail";
import ForgotCode from "../pages/ForgotCode";
import ResetPassword from "../pages/ResetPassword"
import PendingGroups from "../pages/PendingGroups";
import InviteAFriend from "../pages/InviteAFriend";

const AllRoutes = () => {
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("token") ? true : false
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    setAuthenticated(token);
  }, [location.pathname]);

  return (
    <Routes>
      {!authenticated ? (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/newaccount" element={<NewAccount />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/forgotemail" element={<ForgotEmail/>}/>
          <Route path="/code/email" element={<ForgotCode/>}/>
          <Route path="/reset/email" element={<ResetPassword/>}/>
        </>
      ) : (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invite-a-friend" element={<InviteAFriend />}/>
          <Route path="/newgame" element={<NewGame/>}/>
          <Route path="/quiz/:quizId" element={<Quiz/>} />
          <Route path="/result/:quizId" element={<Result />} />
          <Route path="/playerhistory" element={<PlayerHistory />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/invites" element={<Invites/>}/>
          <Route path="/leader" element={<Leaderboard/>}/>
          <Route path="/allgroup" element={<AllGroup/>}/>
          <Route path="/group/:quizId?/:id" element={<GroupName />} />
          <Route path="/newgroup/:id" element={<NewGroup/>}/>
          <Route path="/pending" element={<PendingGroups/>}/>


        </>
      )}
    </Routes>
  );
};

export default AllRoutes;
