import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const InviteFriend = async () => {
    try {
      const response = await PostRequest(`${API_ENDPOINTS.user.inviteFriend}?email=${email}`);
      if (response.status === 200) {
        toast.success(response.message);
        navigate("/dashboard");
        setEmail("");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>

      <div>
        <div className="signup">
          <span className="bg-warning">
            <img src="./images/arrow-left.png" className="hand backbutton" onClick={() => navigate(-1)} alt="direction" />
          </span>
          <div className="signup pop invite-a-friend">

            <div className="row">

              <div className="image">
                <img src="./images/logo.png" alt="game" />
              </div>
              <h4 className="pt-3"> Invite a friend</h4>
              <span className="custom-text-color "> How it works</span>

              <Row className="text-white text-start g-3">
                <Col sm={6} className="d-flex align-items-center">
                  <div className="icon rounded-circle"><i class="fa-solid fa-user-plus"></i></div>
                  <span>Invite your friend</span>
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <div className="icon rounded-circle"><i class="fa-solid fa-list"></i></div>
                  <span>Register to NameDropt</span>
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <div className="icon rounded-circle"><i class="fa-solid fa-gamepad"></i></div>
                  <span>Join the game</span>
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <div className="icon rounded-circle"><i class="fa-solid fa-people-arrows"></i></div>
                  <span>Play together</span>
                </Col>

                <div><p className="m-0 p-0">Enter email to invite your friend</p></div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  required={true}
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <input
                  className="add_btn"
                  type="submit"
                  onClick={InviteFriend}
                  value="Send"
                  class="create"
                />
              </Row>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Login;
