import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const InputCtrl = ({ type, placeholder, label, name, required, value, onChange }) => {
  const { control } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  return (
    <div>
      <label>{label}</label>
      <div style={{ position: 'relative' }}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <input
              type={name === 'password' || name === 'confirmPassword' ? (showPassword ? "text" : "password") : type}
              placeholder={placeholder}
              required={required}
              {...field}
              value={value}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
          )}
        />
        {(name === 'password' || name === 'confirmPassword') && (
          <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-60%)' }}>
            {showPassword ? (
              <FaEyeSlash onClick={() => setShowPassword(false)} style={{ color: 'white' }} />
            ) : (
              <FaEye onClick={() => setShowPassword(true)} style={{ color: 'white' }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputCtrl;
