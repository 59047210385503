export const API_ENDPOINTS = {
  user: {
    login: "/api/User/Login",
    signup: "/api/User/SignUp",
    userDetails: "/api/User/Me",
    savedata: "/api/User/Save",
    stats: "/api/User/GetUserStats",
    leader: "/api/User/GetLeaderBoard",
    userAll:"/api/User/GetAll",
    email:"/api/User/SendPasswordRecoveryCode",
    otp:"/api/User/VerifyOtp",
    password:"/api/User/ChangePassword",
    inviteFriend:"/api/Utility/InviteFriend"
  },
  quiz: {
    generateQuiz: "/api/Quiz/GenerateQuiz",
    sendInvite: "/api/Quiz/SendInvite",
    quiz: "/api/Quiz",
    leagues:"/api/Quiz/GetLeagues",
    Checkans: "/api/Quiz/CheckQuizAnswer",
    allResponses: "/api/Quiz/GetAllQuizResponses",
    quizzes: "/api/Quiz/GetQuizzes",
    invites: "/api/Quiz/GetMyInvites",
    getQuize: "/api/Quiz/GetQuiz",
    reject: "/api/Quiz/RejectInvite",
    accept: "/api/Quiz/AcceptInvite",
    getquiz:"/api/Quiz/GetQuizQuestions"
    
  },
  group:{
    allGroup:"/api/Groups/GetAll",
    saveGroup: "/api/Groups/Save",
    delete:"/api/Groups/Delete",
    get:"/api/Groups/Get",
    members: "/api/Groups/GetMembers",
    add:"/api/Groups/AddOrRemoveMember",
    pending:"/api/Groups/AcceptOrRejectInvite"
  }
 
};

export const QUIZ_LINK = "https://namedropt.com//#/result";

