import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PostRequest } from "../services/api";
import { API_ENDPOINTS } from "../constants/apiEndpoints";
import { toast } from "react-toastify";


const NewAccount = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!firstName || !lastName || !email || !userName || !password || !confirmPassword) {
        throw new Error("All fields are required");
      }

      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const response = await PostRequest(API_ENDPOINTS.user.signup, {
        firstName,
        lastName,
        email,
        userName,
        password,
        roleId: 2,
      });

      if (response.status === 200 && response.message === "Successful") {
        if(response.status === 200 && response.data){
          toast.success(response.message);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.id);
          navigate("/dashboard");
        }
      } else {
        toast.error(response.message)
        console.error("Registration failed", response);
      }
    } catch (error) {
      console.error("Registration failed", error);
      if (error.message === "Passwords do not match") {
        toast.error("Passwords do not match");
      } else if (error.message === "All fields are required") {
        toast.error("All fields are required");
      }
    }
  };

  const signsubmit = () => {
    navigate("/");
  };

  return (
    <>
      <div className="signup">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
            <img src="./images/logo.png" alt="game"/>       
                   <h4 className="pt-3">Create New Account</h4>
              <Form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={firstName}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  value={userName}
                  placeholder="Username"
                  onChange={(e) => setUserName(e.target.value)}
                />

                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <input
                  className="app-btn"
                  type="submit"
                  value="Submit"
                  style={{backgroundColor:"#8e68d8"}}
                />

               {/*  <input
                  className="app-btn"
                  type="button"
                  value="Sign In"
                  onClick={signsubmit}
                /> */}

                <p className="text-light">Already have an account ? <span className="text custom-text-color fw-bold hand " onClick={signsubmit}>Sign In</span></p>
              </Form>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAccount;
