import { useState, useEffect } from "react";

export const useGameTimer = (initialTime) => {
  const [time, setTime] = useState(initialTime-1);
  const [sec, setSec] = useState(59);

  useEffect(() => {
    let timer;

    if (time > 0 || sec > 0) {
      timer = setTimeout(() => {
        if (sec > 0) {
          setSec((prevSec) => prevSec - 1);
        } else if (time > 0) {
          setTime((prevTime) => prevTime - 1);
          setSec(59); 
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [time, sec]);

  return { minutes: time, seconds: sec };
};


